import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Poppins } from 'next/font/google';
import { useState } from 'react';

const poppins = Poppins({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
});

interface props {
  isOpen: boolean;
  closeModal: (emitReport?: boolean, withFilters?: boolean) => void;
  isLoading: boolean;
}

export default function ExportReportModal({
  isOpen,
  closeModal,
  isLoading,
}: props) {
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered size={'lg'}>
      <ModalOverlay />
      <ModalContent fontFamily={poppins.style.fontFamily}>
        <ModalCloseButton />
        <ModalHeader>Exportar relatório</ModalHeader>
        <ModalBody>
          <Checkbox
            isChecked={filtersApplied}
            onChange={(e) => setFiltersApplied(e.target.checked)}
          >
            Exportar relatório com filtros da tabela aplicados
          </Checkbox>
        </ModalBody>
        <ModalFooter display={'flex'} justifyContent={'center'}>
          <Button
            colorScheme='blue'
            isLoading={isLoading}
            onClick={() => closeModal(true, filtersApplied)}
          >
            Exportar relatório
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
