import { isNaN } from 'lodash';

/**
 * Parse a string from datetime format to brazilian locale format
 * @param dateTime
 * @returns
 */
export const parseDate = (dateTime: string) => {
  const [yyyy, mm, dd] = dateTime?.split('T')[0].split('-');

  return `${dd}/${mm}/${yyyy}`;
};

/**
 * Parse a string from datetime format to brazilian time format. (e.g. "19:10")
 * @param dateTime
 * @returns
 */
export const parseTime = (dateTime: string) => {
  const dateObject = new Date(dateTime);
  return `${dateObject.getUTCHours()}:${dateObject.getUTCMinutes()}`;
};

/**
 * Parse the date for brazilian contracts. (e.g. "18 de Dezembro de 2009")
 * @param dateTime
 * @returns
 */
export const parseDateForContract = (dateTime: string) => {
  if (!dateTime) return '';
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const [yyyy, mm, dd] = dateTime?.split('T')[0].split('-');

  return `${dd} de ${months[Number(mm) - 1]} de ${yyyy}`.toUpperCase();
};

/**
 * Convert datetime string to unix time
 * @param dateTime
 * @returns
 */
export const convertDateToUnix = (dateTime: string) => {
  return !isNaN(new Date(dateTime))
    ? Math.floor(new Date(dateTime).getTime() / 1000)
    : null;
};
