import { doctorOnboardingAPI, handleAPIReq } from '@/api';
import {
  type StatusLabel,
  providerLogoSizes,
  statusName,
} from '@/utils/constants';
import { parseDate } from '@/utils/date';
import {
  CheckCircleIcon,
  EditIcon,
  QuestionIcon,
  ViewIcon,
  WarningIcon,
} from '@chakra-ui/icons';
import {
  Badge,
  Button,
  HStack,
  Image,
  Text,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { addPonctuationToCNPJ, addPonctuationToCPF } from '@commons/js-utils';
import {
  type Admin,
  type ProfessionalListResponseData,
  ProfessionalStatus,
  ProfessionalSyncStatus,
  type Provider,
  WorkflowStatus,
} from '@medsimples/doctor-onboarding-openapi-v2';
import { Pagination, Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import * as E from 'fp-ts/Either';
import { Poppins } from 'next/font/google';
import { useRouter } from 'next/navigation';
import { useSearchParams } from 'next/navigation';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useFlags } from '../../providers/feature_flag_provider';
import SyncButton from '../buttons/sync_button';
import SyncProfessionalModal from '../modals/sync_professional_modal';

interface props {
  textSearch: string;
  tabStatus: StatusLabel[];
  admin: Admin;
  resetSearch: () => void;
}

interface TableParams {
  field?: string;
  order?: string;
  filters?: Record<string, FilterValue>;
}

const statusesAbleToContract = ['WAITING_SYNCHRONIZATION', 'ENABLED'];

const poppins = Poppins({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
});

const filtersLabels = {
  crmUF: 'Estado',
  specialties: 'Especialidades',
  status: 'Status',
  providersIds: 'Origem',
};

export function DoctorsTable({
  textSearch,
  tabStatus,
  admin,
  resetSearch,
}: props) {
  const toast = useToast();
  const searchParams = useSearchParams();
  const router = useRouter();
  const ffs = useFlags();
  const page = Number(searchParams.get('page')) || 0;
  const limit = Number(searchParams.get('limit')) || 10;

  const [isLoading, setIsLoading] = useState(true);
  const [isContractLoading, setContractLoading] = useState(false);
  const [isDetailsLoading, setDetailsLoading] = useState(false);
  const [doctors, setDoctors] = useState<ProfessionalListResponseData[]>([]);
  const [total, setTotal] = useState<number>();
  const [specialties, setSpecialties] = useState<
    { text: string; value: string }[]
  >([]);
  const [providers, setProviders] = useState<Provider[]>(null);
  const [tableParams, setTableParams] = useState<TableParams>({});
  const [isSyncModalOpen, setSyncModalOpen] = useState(false);
  const [syncModalDocId, setSyncModalDocId] = useState<string>(null);

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<ProfessionalListResponseData>,
  ) => {
    setTableParams({
      filters,
      field: String(sorter.field),
      order: sorter.order,
    });
  };

  const handlePageChange = (page: number, pageSize: number) => {
    const params = {
      page: page - 1,
      limit: pageSize,
      search: textSearch,
      sort: tableParams.field,
      order: tableParams.order,
      filters: JSON.stringify(tableParams.filters),
    };

    router.replace(
      Object.entries(params).reduce(
        (params, [key, value]) => params + (value ? `&${key}=${value}` : ''),
        '?',
      ),
    );
  };

  const resetFilters = () => {
    setTableParams({
      ...tableParams,
      filters: {},
    });
    resetSearch();
  };

  const getDoctorsList = async () => {
    setIsLoading(true);
    try {
      const response = await doctorOnboardingAPI.admin.listProfessionals({
        search: searchParams.get('search'),
        sorting: {
          sort: searchParams.get('sort'),
          order: searchParams.get('order'),
        },
        filters: searchParams.get('filters')
          ? JSON.parse(searchParams.get('filters'))
          : {},
        pagination: {
          offset: page * limit,
          limit,
        },
      });
      setDoctors(response.data);
      setTotal(response.metadata.pagination.total);
    } catch (e) {
      console.error(e);
      toast({
        description:
          'Houve um problema ao buscar a lista de médicos, por favor tente novamente.',
        status: 'warning',
        containerStyle: {
          fontFamily: poppins.style.fontFamily,
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const redirectToDetails = (professionalId: string) => {
    setDetailsLoading(true);
    setIsLoading(true);
    router.push(`/doctors/${professionalId}`);
  };

  const sendContract = (cnpj: string) => {
    setContractLoading(true);
    router.push(`/contract/elaborate?cnpj=${cnpj}`);
  };

  const onClickSyncProfessional = async (professionalId: string) => {
    setSyncModalOpen(true);
    setSyncModalDocId(professionalId);
  };

  const onCloseSyncModal = () => {
    setSyncModalOpen(false);
  };

  const onSync = (groupIds: string[]) => {
    syncProfessional(syncModalDocId, groupIds).finally(() =>
      setSyncModalOpen(false),
    );
  };

  const syncProfessional = async (
    professionalId: string,
    groupIds: string[],
  ) => {
    setIsLoading(true);
    setSyncModalOpen(false);
    const res = await handleAPIReq(() =>
      doctorOnboardingAPI.admin.syncProfessional({
        professionalId,
        shiftManagerGroupIds: groupIds,
      }),
    );
    if (E.isLeft(res)) {
      toast({
        description: res.left,
        status: 'error',
        containerStyle: {
          fontFamily: poppins.style.fontFamily,
        },
      });
      setIsLoading(false);
      return;
    }

    toast({
      description:
        'Médico enviado para a sincronização, em breve seu status será atualizado automaticamente.',
      status: 'success',
      containerStyle: {
        fontFamily: poppins.style.fontFamily,
      },
    });
    await getDoctorsList();
    setIsLoading(false);
  };

  const buttonIcon = useCallback(
    (status: string, providerId: string) => {
      if (
        (status === ProfessionalStatus.WAITING_APPROVAL &&
          admin?.permissions?.['admin.gdmApproval']?.includes(providerId)) ||
        (status === ProfessionalStatus.WAITING_COMPLIANCE &&
          admin?.permissions?.['admin.complianceApproval']?.includes(
            providerId,
          ))
      ) {
        return <EditIcon />;
      }

      return <ViewIcon />;
    },
    [admin?.permissions],
  );

  const buttonLabel = useCallback(
    (status: string, providerId: string) => {
      if (
        (status === ProfessionalStatus.WAITING_APPROVAL &&
          admin?.permissions?.['admin.gdmApproval']?.includes(providerId)) ||
        (status === ProfessionalStatus.WAITING_COMPLIANCE &&
          admin?.permissions?.['admin.complianceApproval']?.includes(
            providerId,
          ))
      ) {
        return 'Analisar';
      }

      return 'Visualizar';
    },
    [admin?.permissions],
  );

  const syncProfessionalPermission = (providerId: string) =>
    admin?.permissions?.['admin.syncProfessional']?.includes(providerId);

  const columns: ColumnsType<ProfessionalListResponseData> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 80,
      sorter: true,
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'CPF',
      key: 'cpf',
      dataIndex: 'cpf',
      render: (_, record) => addPonctuationToCPF(record.cpf),
      sorter: true,
    },
    {
      title: 'CNPJ',
      key: 'cnpj',
      dataIndex: 'cnpj',
      render: (_, record) => addPonctuationToCNPJ(record.cnpj),
      sorter: true,
    },
    {
      title: 'CRM',
      key: 'crm',
      dataIndex: 'crm',
      render: (_, record: ProfessionalListResponseData) =>
        record.register?.register,
      sorter: true,
    },
    {
      title: 'Estado CRM',
      key: 'crmUF',
      dataIndex: 'crmUF',
      filters: doctors
        ?.filter(
          (doctor, index, doctors) =>
            doctors.findIndex(
              (item) =>
                item.register?.additionalData?.crmUF ===
                doctor.register?.additionalData?.crmUF,
            ) === index,
        )
        ?.map((doctor) => ({
          text: doctor.register?.additionalData?.crmUF,
          value: doctor.register?.additionalData?.crmUF,
        })),
      render: (_, record: ProfessionalListResponseData) =>
        record.register?.additionalData?.crmUF,
      filteredValue: tableParams.filters?.crmUF || null,
    },
    {
      title: 'Especialidades',
      key: 'specialties',
      dataIndex: 'specialties',
      filters: specialties,
      filterSearch: true,
      render: (_, record: ProfessionalListResponseData) =>
        record.specialties?.map((specialty, index) =>
          renderDoctorSpecialties(index, specialty),
        ) ?? '',
      filteredValue: tableParams.filters?.specialties || null,
    },
    {
      title: 'Atualizado em',
      key: 'updated_at',
      dataIndex: 'updated_at',
      defaultSortOrder: 'descend',
      render: (_, { updated_at }) =>
        `${parseDate(updated_at)} às ${new Date(updated_at).toLocaleTimeString(
          'pt-BR',
        )}`,
      sorter: (a, b) =>
        new Date(a.updated_at).getTime() > new Date(b.updated_at).getTime()
          ? 1
          : -1,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      filters: [
        WorkflowStatus.ACTION_PENDING,
        ...Object.values(ProfessionalStatus),
      ].map((status) => ({
        text: statusName[status],
        value: status,
      })),
      render: (_, record: ProfessionalListResponseData) =>
        statusName[record.status],
      filteredValue: tableParams.filters?.status || null,
    },
    {
      title: 'Origem',
      key: 'providersIds',
      dataIndex: 'providersIds',
      render: (_, { providerId }) => {
        const provider = providers?.find((p) => p.id === providerId);
        return (
          <Image
            src={provider?.logo_uri}
            alt={provider?.display_name}
            maxH={providerLogoSizes[provider?.slug]}
            marginLeft={'0.5em'}
          />
        );
      },
      filters: providers?.map((provider) => ({
        text: provider.display_name,
        value: provider.id,
      })),
      filteredValue: tableParams.filters?.providersIds || null,
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <VStack>
          {ffs.PROFESSIONAL_SYNC_BUTTON &&
            syncProfessionalPermission(record.providerId) &&
            record.status === ProfessionalStatus.WAITING_SYNCHRONIZATION && (
              <SyncButton
                size='md'
                isDisabled={[
                  ProfessionalSyncStatus.UNABLE,
                  ProfessionalSyncStatus.SENT,
                ].includes(record.sync_status)}
                onClick={() => onClickSyncProfessional(record.id)}
              />
            )}
          {statusesAbleToContract.includes(record.status) &&
            ffs.CONTRACT_MODULE && (
              <Button
                leftIcon={<EditIcon />}
                fontFamily={poppins.style.fontFamily}
                colorScheme='blue'
                variant='outline'
                size='md'
                style={{
                  width: '150px',
                  fontWeight: 300,
                  borderRadius: '7px',
                  boxShadow: 'none',
                  padding: '1rem',
                }}
                onClick={() => sendContract(record.cnpj)}
                isLoading={isContractLoading}
              >
                Contratar
              </Button>
            )}

          {Boolean(record.status !== ProfessionalStatus.REGISTERING) && (
            <Button
              leftIcon={buttonIcon(record.status, record.providerId)}
              fontFamily={poppins.style.fontFamily}
              colorScheme='blue'
              variant='outline'
              size='md'
              style={{
                width: '150px',
                fontWeight: 300,
                borderRadius: '7px',
                boxShadow: 'none',
                padding: '1rem',
              }}
              onClick={() => redirectToDetails(record.id)}
              isLoading={isDetailsLoading}
            >
              {buttonLabel(record.status, record.providerId)}
            </Button>
          )}
        </VStack>
      ),
    },
  ];

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    Promise.all([
      handleAPIReq(() =>
        doctorOnboardingAPI.admin.listDoctorsSpecialties(),
      ).then((res) => {
        if (E.isLeft(res)) {
          toast({
            description: res.left,
            status: 'error',
            containerStyle: {
              fontFamily: poppins.style.fontFamily,
            },
          });
          return;
        }
        const specialties = res.right.data
          ?.filter(
            (specialty, index) =>
              res.right.data?.findIndex(
                (item) => item.name === specialty.name,
              ) === index,
          )
          .map((specialty) => ({
            text: specialty.name,
            value: specialty.name,
          }));
        setSpecialties(Array.isArray(specialties) ? specialties : []);
      }),
      handleAPIReq(() => doctorOnboardingAPI.admin.listProviders()).then(
        (res) => {
          if (E.isLeft(res)) {
            toast({
              description: res.left,
              status: 'error',
              containerStyle: {
                fontFamily: poppins.style.fontFamily,
              },
            });
            return;
          }
          setProviders(res.right.data);
        },
      ),
    ]);
  }, []);

  useEffect(() => {
    const params = {
      search: textSearch,
      page: 0,
      sort: tableParams.field,
      order: tableParams.order,
      filters: JSON.stringify(tableParams.filters),
    };

    router.replace(
      Object.entries(params).reduce(
        (params, [key, value]) => params + (value ? `&${key}=${value}` : ''),
        '?',
      ),
    );
  }, [textSearch, tableParams, router]);

  useEffect(() => {
    setTableParams((current) => ({
      ...current,
      filters: {
        ...current.filters,
        status: tabStatus.length ? tabStatus : null,
      },
    }));
  }, [tabStatus]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    getDoctorsList();
  }, [searchParams]);

  const renderActiveFilters = () => {
    return Object.values(tableParams?.filters || {}).filter((value) => value)
      .length || searchParams.get('search') ? (
      <HStack justifyContent={'flex-end'} marginBottom={3}>
        <Text>Filtros ativos:</Text>
        {searchParams.get('search') ? (
          <Badge colorScheme='green'>Nome</Badge>
        ) : null}
        {Object.entries(tableParams?.filters || {}).map(([filter, value]) =>
          value ? (
            <Badge key={filter} colorScheme='green'>
              {filtersLabels[filter]}
            </Badge>
          ) : null,
        )}
        <Button
          marginLeft={3}
          size={'xs'}
          colorScheme='blue'
          onClick={resetFilters}
          variant={'link'}
          isDisabled={isLoading}
        >
          Limpar filtros
        </Button>
      </HStack>
    ) : null;
  };

  const renderDoctorSpecialties = (index, specialty) => {
    const iconData = {
      DENIED: { label: 'Negado', icon: <WarningIcon color='#db3737' /> },
      PENDING: { label: 'Pendente', icon: <QuestionIcon color='orange' /> },
      APPROVED: {
        label: 'Aprovado',
        icon: <CheckCircleIcon color='#4ebd26' />,
      },
    }[specialty.status] ?? {
      label: 'Aprovado pelo CFM',
      icon: <CheckCircleIcon color='#63B3ED' />,
    };

    return (
      <HStack key={index}>
        <Tooltip label={iconData.label} placement='top'>
          {iconData.icon}
        </Tooltip>
        <p>{specialty.specialty}</p>
      </HStack>
    );
  };

  return (
    <>
      {renderActiveFilters()}
      <Table
        virtual
        scroll={{ x: 2000 }}
        loading={isLoading}
        rowKey={'id'}
        columns={columns}
        dataSource={doctors}
        onChange={handleTableChange}
        pagination={false}
      />
      {!isLoading ? (
        <HStack justifyContent={'center'} marginTop={4}>
          <Pagination
            showSizeChanger
            current={page + 1}
            pageSize={limit}
            onChange={handlePageChange}
            total={total}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} de ${total} médicos`
            }
          />
        </HStack>
      ) : null}
      <SyncProfessionalModal
        isOpen={isSyncModalOpen}
        onClose={onCloseSyncModal}
        onSync={onSync}
      />
    </>
  );
}

export default function DoctorsTableSuspense(props: props) {
  return (
    <Suspense>
      <DoctorsTable {...props} />
    </Suspense>
  );
}
