'use client';

import { doctorOnboardingAPI, handleAPIReq } from '@/api';
import DoctorsTable from '@/app/components/doctors/table';
import { useDebounce } from '@/hooks/useDebounce';
import { tokens } from '@/styles/tokens';
import { type StatusLabel, statusName } from '@/utils/constants';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  Center,
  Flex,
  Input,
  Spinner,
  Tab,
  TabList,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import { unaccentLower } from '@commons/js-utils';
import type {
  Admin,
  ProfessionalsStatusesCountData,
} from '@medsimples/doctor-onboarding-openapi-v2';
import * as E from 'fp-ts/Either';
import { Poppins } from 'next/font/google';
import { useSearchParams } from 'next/navigation';
import { Suspense, useCallback, useEffect, useState } from 'react';
import ExportReportModal from '../components/modals/exportReportModal';
import { useAuth } from '../providers/auth_provider';

const poppins = Poppins({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
});

function DoctorsPage() {
  const [currentStatus, setCurrentStatus] = useState<StatusLabel[]>([]);
  const [statusCount, setStatusCount] =
    useState<ProfessionalsStatusesCountData>();
  const [textSearch, setTextSearch] = useState<string>();
  const textSearchDebounced = useDebounce(textSearch, 700);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [admin, setAdmin] = useState<Admin>(null);
  const [isExportReportModalOpen, setIsExportReportModalOpen] =
    useState<boolean>(false);

  const auth = useAuth();
  const toast = useToast();
  const searchParams = useSearchParams();

  const getStatusesCount = async () => {
    if (!auth.user) return;

    setIsLoading(true);
    try {
      const response = await doctorOnboardingAPI.admin.statusesCount();
      setStatusCount(response.data);
    } catch (e) {
      console.error(e);
      toast({
        description:
          'Houve um problema ao buscar a contagem de médicos, por favor tente novamente.',
        status: 'warning',
        containerStyle: {
          fontFamily: poppins.style.fontFamily,
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const closeExportModal = async (
    emitReport?: boolean,
    withFilters?: boolean,
  ) => {
    if (emitReport) {
      setIsLoading(true);
      const bufferReport = await doctorOnboardingAPI.admin
        .exportReport(
          withFilters
            ? {
                search: searchParams.get('search'),
                sorting: {
                  sort: searchParams.get('sort'),
                  order: searchParams.get('order'),
                },
                filters: searchParams.get('filters')
                  ? JSON.parse(searchParams.get('filters'))
                  : {},
              }
            : {
                search: null,
                sorting: {
                  sort: null,
                  order: null,
                },
                filters: searchParams.get('filters')
                  ? JSON.parse(searchParams.get('filters'))
                  : {},
              },
        )
        .finally(() => setIsLoading(false));

      const filters = searchParams.get('filters')
        ? JSON.parse(searchParams.get('filters'))
        : {};

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(bufferReport);
      link.download = unaccentLower(
        `medicos-${
          statusName[filters.status || 'ALL']
        }-${new Date().toLocaleDateString('pt-BR')}.xlsx`.replaceAll(' ', '-'),
      );
      link.click();
    }
    setIsExportReportModalOpen(false);
  };

  useEffect(() => {
    getStatusesCount();
  }, [searchParams, auth.user]);

  useEffect(() => {
    if (!auth.user) return;
    setIsLoading(true);
    Promise.all([
      handleAPIReq(() => doctorOnboardingAPI.admin.meAdmin()).then((res) => {
        if (E.isLeft(res)) {
          toast({
            description: res.left,
            status: 'error',
            containerStyle: {
              fontFamily: poppins.style.fontFamily,
            },
          });
          return;
        }
        setAdmin(res.right.data);
      }),
    ]).finally(() => setIsLoading(false));
  }, [auth.user]);

  const getStatusCount = useCallback(
    (status: StatusLabel) => {
      return statusCount?.[status] ? statusCount[status] : 0;
    },
    [statusCount],
  );

  const findStatusIndex = (value: StatusLabel) => {
    const filters = JSON.parse(searchParams.get('filters'));
    return filters?.status?.length === 1
      ? filters.status[0] === value
      : 'ALL' === value;
  };

  return auth.user ? (
    <Card width={'100%'} paddingY={6} paddingX={6}>
      <Flex justifyContent='flex-start' alignItems='center' marginBottom={8}>
        <Tabs
          width='100%'
          variant='soft-rounded'
          colorScheme='blue'
          index={Object.keys(statusName).findIndex(findStatusIndex)}
          onChange={(index) =>
            setCurrentStatus(
              Object.keys(statusName)[index] === 'ALL'
                ? []
                : ([Object.keys(statusName)[index]] as StatusLabel[]),
            )
          }
        >
          <TabList width='100%' justifyContent='center'>
            {Object.entries(statusName).map(([status, statusLabel]) => (
              <Tab
                key={status}
                fontWeight={findStatusIndex(status as StatusLabel) ? 500 : 300}
                fontSize={14}
                isDisabled={isLoading}
              >
                {`${statusLabel} (${getStatusCount(status as StatusLabel)})`}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Flex>

      <Flex
        justifyContent='flex-start'
        alignItems='center'
        flexWrap='nowrap'
        background={tokens.background.ice.color}
        paddingX={8}
        paddingY={6}
        marginBottom={8}
      >
        <Input
          placeholder='Digite um CPF, CNPJ, CRM ou nome para pesquisar'
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value)}
          isDisabled={isLoading}
          variant='outline'
          background='white'
          marginRight={4}
        />
        <Button
          variant={'outline'}
          colorScheme={tokens.button.primary.scheme}
          fontWeight={400}
          backgroundColor={tokens.text.white.color}
          size={'lg'}
          isDisabled={isLoading}
          onClick={() => setIsExportReportModalOpen(true)}
          leftIcon={<ExternalLinkIcon />}
        >
          Exportar relatório
        </Button>
      </Flex>
      <DoctorsTable
        textSearch={textSearchDebounced as string}
        tabStatus={currentStatus}
        admin={admin}
        resetSearch={() => setTextSearch('')}
      />
      <ExportReportModal
        isOpen={isExportReportModalOpen}
        closeModal={closeExportModal}
        isLoading={isLoading}
      />
    </Card>
  ) : (
    <Center width={'100%'} marginTop={'30vh'} verticalAlign={'center'}>
      <Spinner size={'xl'} />
    </Center>
  );
}

export default function DoctorsPageSuspense() {
  return (
    <Suspense>
      <DoctorsPage />
    </Suspense>
  );
}
